import ApiTypes from '../../types';
import Types from './types';
import toast from 'react-hot-toast';

interface IProfileResponse {
  id: string;
  company: {
    companyRef: {
      companyId: string;
      companyName: string;
    };
    owner: boolean;
  };
  role: UserRoleType;
  data: {
    displayName: string;
    accessToken: string;
    email: string;
    photoUrl: string;
  };
  subscription: ISubscription;
}

const initialState: IAuth = {
  createActionId: '',
  errors: [],
  inviteError: '',
  invitedCompanyId: '',
  isAccountDeactivated: false,
  isLoading: false,
  isLoadingInvite: false,
  isNewUser: false,
  isTokenOutdated: false,
  lastRoute: {},
  profile: null,
  shareError: '',
  sharedLinkData: null,
  token: '',
  users: {
    count: 0,
    page: {},
    pendingClearCategoryData: null,
    pendingClearInvitedCategoryData: null,
    pendingDeleteId: '',
    pendingShareData: null,
    pendingShareInvitedData: null,
    pendingSwitchStatusId: '',
    pendingSwitchUserRoleData: null,
    statusUserList: 'All Users',
  },
  usersEmails: [],
};

export default (state: IAuth = initialState, action: IAction) => {
  switch (action.type) {
    case Types.SET_TOKEN: {
      const newState = {
        ...state,
      };
      if (action.payload) {
        newState.isTokenOutdated = false;
        newState.token = action.payload;
      }
      return newState;
    }

    case Types.USER_ROUTE: {
      const newState = {
        ...state
      };
      newState.lastRoute = { [state.profile.id]: action.payload };
      return newState;
    }

    case ApiTypes.API_UNAUTHORIZED: {
      return {
        ...state,
        isTokenOutdated: true,
        token: ''
      };
    }

    case ApiTypes.API_DEACTIVATED: {
      return {
        ...state,
        isAccountDeactivated: true,
        isLoading: false,
        token: ''
      };
    }

    case Types.SET_TOKEN_OUTDATED: {
      return {
        ...state,
        isTokenOutdated: action.payload,
        token: ''
      };
    }

    case Types.SET_ACCOUNT_DEACTIVATED: {
      return {
        ...state,
        isAccountDeactivated: false
      };
    }

    case Types.SET_USER_LIST_STATUS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnFetch: false,
      };

      if (newState.users) {
        newState.users.statusUserList = action.payload;
      }

      return newState;
    }

    case Types.INVITE_USERS_NOT_ALLOWED:
    case Types.INVITE_USERS_ALLOWED:
    case Types.INVITE_USERS_REQUEST: {
      return {
        ...state,
        errors: [],
        inviteError: '',
        isLoadingInvite: true
      };
    }

    case Types.PROFILE_REQUEST:
    case Types.CLOSE_ACCOUNT_REQUEST:
    case Types.CREATE_COMPANY_REQUEST:
    case Types.FETCH_USERS_EMAIL_REQUEST:
    case Types.FETCH_USERS_REQUEST:
    case Types.REINVITE_USER_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoading: true
      };
    }

    case Types.SHARE_CATEGORY_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: true,
        isLoadingOnFetch: false,
      };

      if (newState.users) {
        newState.users.pendingShareData = {
          categoriesIds: action.payload.categoriesIds,
          emails: action.payload.emails,
          role: action.payload.role,
        };
      }

      return newState;
    }

    case Types.SHARE_INVITED_CATEGORY_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: true,
        isLoadingOnFetch: false,
      };

      if (newState.users) {
        newState.users.pendingShareInvitedData = {
          categoriesIds: action.payload.categoriesIds,
          role: action.payload.role,
          userId: action.payload.userId,
        };
      }

      return newState;
    }

    case Types.CLEAR_CATEGORY_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: true,
        isLoadingOnFetch: false,
      };

      if (newState.users) {
        newState.users.pendingClearCategoryData = {
          categoriesIds: action.payload.categoriesIds,
          id: action.payload.id,
        };
      }

      return newState;
    }

    case Types.CLEAR_INVITED_CATEGORY_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: true,
        isLoadingOnFetch: false,
      };

      if (newState.users) {
        newState.users.pendingClearInvitedCategoryData = {
          categoriesIds: action.payload.categoriesIds,
          userId: action.payload.userId,
        };
      }

      return newState;
    }

    case Types.SWITCH_USER_STATUS_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: true
      };
      newState.users.pendingSwitchStatusId = action.payload;

      return newState;
    }

    case Types.SWITCH_USER_ROLE_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: true
      };
      newState.users.pendingSwitchUserRoleData = { userId: action.payload.userId, role: action.payload.role };

      return newState;
    }

    case Types.DELETE_USER_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: true
      };

      if (newState.users) {
        newState.users.pendingDeleteId = action.payload.id;
      }

      return newState;
    }

    case Types.FETCH_USERS_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false
      };

      const response = action.payload.page;
      if (response) {
        delete newState.users.page;
        newState.users.count = action.payload.count;
        response.map((user: IUser) => {
          if (!newState.users.page) {
            newState.users.page = { [user.id]: user };
          }
          newState.users.page[user.id] = user;
        });
      }

      return newState;
    }

    case Types.FETCH_USERS_EMAIL_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false
      };

      newState.usersEmails = action.payload;
      return newState;
    }

    case Types.SHARE_CATEGORY_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false
      };

      newState.users?.pendingShareData?.categoriesIds?.map((categoryId) => {
        const userIds: string[] = [];
        const users = getUsersArray(newState);
        newState.users.pendingShareData.emails.map((email) => {
          const user = users.find((us) => us?.data?.email === email);
          if (user) {
            userIds.push(user.id);
          }
        });
        userIds?.map((userId) => {
          if (newState.users.page[userId]) {
            if (newState.users.page[userId].categories.find((category) => category.categoryId === categoryId)) {
              newState.users.page[userId].categories =
                newState.users.page[userId].categories.filter((category) => category.categoryId !== categoryId);
            }
            newState.users.page[userId].categories.push(
              { categoryId, role: newState.users.pendingShareData.role });
          }
        });
      });
      newState.users.pendingShareData = null;

      return newState;
    }

    case Types.SHARE_INVITED_CATEGORY_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false
      };

      newState.users?.pendingShareInvitedData?.categoriesIds?.map((categoryId) => {
        const userId = newState.users.pendingShareInvitedData.userId;
        if (newState.users.page[userId]) {
          if (newState.users.page[userId].categories.find((category) => category.categoryId === categoryId)) {
            newState.users.page[userId].categories =
              newState.users.page[userId].categories.filter((category) => category.categoryId !== categoryId);
          }
          newState.users.page[userId].categories.push(
            { categoryId, role: newState.users.pendingShareInvitedData.role });
        }
      });
      newState.users.pendingShareInvitedData = null;

      return newState;
    }

    case Types.CLEAR_CATEGORY_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnFetch: false,
      };

      if (newState.users.pendingClearCategoryData) {
        const categoriesIds = newState.users.pendingClearCategoryData.categoriesIds;
        const userId = newState.users.pendingClearCategoryData.id;
        categoriesIds?.map((categoryId: string) => {
          if (newState.users.page[userId]) {
            newState.users.page[userId].categories = newState.users.page[userId].categories.filter(
              (category) => category.categoryId !== categoryId
            );
          }
        });
      }
      newState.users.pendingClearCategoryData = null;

      return newState;
    }

    case Types.CLEAR_INVITED_CATEGORY_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnFetch: false,
      };

      if (newState.users.pendingClearInvitedCategoryData) {
        const categoriesIds = newState.users.pendingClearInvitedCategoryData.categoriesIds;
        const userId = newState.users.pendingClearInvitedCategoryData.userId;
        categoriesIds?.map((categoryId: string) => {
          if (newState.users.page[userId]) {
            newState.users.page[userId].categories = newState.users.page[userId].categories.filter(
              (category) => category.categoryId !== categoryId
            );
          }
        });
      }
      newState.users.pendingClearInvitedCategoryData = null;

      return newState;
    }

    case Types.PROFILE_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false
      };

      if (action.payload) {
        const response = action.payload as IProfileResponse;
        const company = response.company.companyRef
          ? {
            companyId: response.company.companyRef.companyId,
            companyName: response.company.companyRef.companyName,
            owner: response.company.owner
          }
          : null;
        const profile = {
          ...response,
          company
        };
        newState.profile = { ...profile };
      }

      return newState;
    }

    case Types.SET_INVITED_COMPANY_ID: {
      return {
        ...state,
        errors: new Array<string>(),
        invitedCompanyId: action.payload.companyId,
        isLoading: false
      };
    }

    case Types.SET_SHARED_LINK: {
      return {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        sharedLinkData: action.payload.encryptedData
      };
    }

    case Types.SET_CREATE_ACTION_ID: {
      return {
        ...state,
        createActionId: action.payload.actionId,
        errors: new Array<string>(),
        isLoading: false
      };
    }

    case Types.SET_NEW_USER: {
      return {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isNewUser: action.payload,
      };
    }

    case Types.ACCESS_VIA_LINK_SUCCESS: {
      return {
        ...state,
        sharedLinkData: '',
        token: '',
      };
    }

    case Types.ACCESS_VIA_LINK_ERROR: {
      toast.error('Invalid shared link');
      return {
        ...state,
        sharedLinkData: ''
      };
    }

    case Types.INVITE_USERS_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        inviteError: '',
        isLoadingInvite: false
      };

      const users = action.payload as IUser[];
      if (users) {
        newState.users.count += users.length;
        users.map((user: IUser) => {
          newState.users.page[user.id] = user;
        });
      }

      return newState;
    }

    case Types.SWITCH_USER_STATUS_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: true
      };

      newState.users.page = { ...state.users.page };
      if (!newState.users.page[newState.users.pendingSwitchStatusId].isActive) {
        newState.users.count += 1;
      } else {
        newState.users.count -= 1;
      }
      newState.users.page[newState.users.pendingSwitchStatusId].isActive =
        !newState.users.page[newState.users.pendingSwitchStatusId].isActive;
      newState.users.pendingSwitchStatusId = '';

      return newState;
    }

    case Types.SWITCH_USER_ROLE_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: true
      };

      if (newState.users && newState.users.pendingSwitchUserRoleData && newState.users.page
        && newState.users.page[newState.users.pendingSwitchUserRoleData.userId]) {
        newState.users.page[newState.users.pendingSwitchUserRoleData.userId].role
          = newState.users.pendingSwitchUserRoleData.role;
        newState.users.pendingSwitchUserRoleData = null;
      }

      return newState;
    }

    case Types.DELETE_USER_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: true
      };

      if (newState.users && newState.users.pendingDeleteId) {
        if (newState.users.page[newState.users.pendingDeleteId].isActive !== false) {
          newState.users.count -= 1;
        }
        delete newState.users.page[newState.users.pendingDeleteId];
        newState.users.pendingDeleteId = '';
      }

      return newState;
    }

    case Types.CLOSE_ACCOUNT_SUCCESS:
    case Types.CREATE_COMPANY_SUCCESS:
    case Types.REINVITE_USER_SUCCESS: {
      return {
        ...state,
        errors: [],
        invitedCompanyId: null,
        isLoading: false
      };
    }

    case Types.SHARE_CATEGORY_ERROR: {
      const newState = {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false,
        shareError: action.payload,
      };

      if (newState.users) {
        newState.users.pendingShareData = null;
      }

      return newState;
    }

    case Types.SHARE_INVITED_CATEGORY_ERROR: {
      const newState = {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false
      };

      if (newState.users) {
        newState.users.pendingShareInvitedData = null;
      }

      return newState;
    }

    case Types.CLEAR_CATEGORY_ERROR: {
      const newState = {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false
      };

      if (newState.users) {
        newState.users.pendingClearCategoryData = null;
      }

      return newState;
    }

    case Types.CLEAR_INVITED_CATEGORY_ERROR: {
      const newState = {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false
      };

      if (newState.users) {
        newState.users.pendingClearInvitedCategoryData = null;
      }

      return newState;
    }

    case Types.SWITCH_USER_STATUS_ERROR: {
      const newState = {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false
      };

      if (newState.users) {
        newState.users.pendingSwitchStatusId = '';
      }

      return newState;
    }

    case Types.SWITCH_USER_ROLE_ERROR: {
      const newState = {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false
      };

      if (newState.users) {
        newState.users.pendingSwitchUserRoleData = null;
      }

      return newState;
    }

    case Types.DELETE_USER_SUCCESS: {
      const newState = {
        ...state,
        errors: [],
        isLoading: false,
        isLoadingOnFetch: false
      } as IAuth;

      if (newState.users) {
        newState.users.pendingDeleteId = '';
      }

      return newState;
    }

    case Types.CLEAR_INVITE_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        inviteError: '',
        isLoading: false
      } as IAuth;
    }

    case Types.CLEAR_SHARE_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoading: false,
        shareError: '',
      } as IAuth;
    }

    case Types.INVITE_USERS_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        inviteError: action.payload,
        isLoadingInvite: false
      } as IAuth;
    }

    case Types.AUTH_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        invitedCompanyId: null,
        isLoading: false,
        sharedLinkData: null,
      } as IAuth;
    }

    default:
      return state;
  }
};

// selectors:
export const getUsersArray = (
  state: IAuth,
): IUser[] => {
  return state.users?.page ? Object.values(state.users.page) : null;
};

export const getActiveUsers = (
  users: IUser[]
): IUser[] => {
  return users?.filter((user) => user.isActive);
};

export const getPendingUsers = (
  users: IUser[]
): IUser[] => {
  return users?.filter((user) => user.isActive === undefined);
};

export const getDeactivatedUsers = (
  users: IUser[]
): IUser[] => {
  return users?.filter((user) => user.isActive === false);
};

export const getUsersEditCategoriesAndProjects = (
  state: IStore,
  users: IUser[]
): IUserCategory => {
  const { categoriesState, projectState } = state;
  const usersCategories: IUserCategory = {};
  users?.map((user: IUser) => {
    const items = categoriesState.categories.concat(projectState.projects);
    usersCategories[user.id] = items.map((i) => {
      let checked = false;
      const item = user?.categories?.find((userCategory) => userCategory.categoryId === i.id);
      if (item) {
        checked = item.role === 'moderator' || item.role === 'admin';
      }
      return {
        checked,
        id: i.id,
        name: i.name,
      };
    });
  });
  return usersCategories;
};

export const getUsersViewCategoriesAndProjects = (
  state: IStore,
  users: IUser[]
): IUserCategory => {
  const { categoriesState, projectState } = state;
  const usersCategories: IUserCategory = {};
  users?.map((user: IUser) => {
    const items = categoriesState.categories.concat(projectState.projects);
    usersCategories[user.id] = items.map((i) => {
      let checked = false;
      const item = user?.categories?.find((userCategory) => userCategory.categoryId === i.id);
      if (item) {
        checked = item.role === 'user' || user.role === 'moderator' || user.role === 'admin';
      }
      return {
        checked,
        id: i.id,
        name: i.name,
      };
    });
  });
  return usersCategories;
};
