import React, {createRef, PropsWithChildren, useEffect} from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import DashboardTabs from '@components/tabs';
import Notifications from '@components/notifications';
import SyncGoogleDrive from '@components/button-sync-google-drive';
import { saveUserRoute } from '@redux/reducers/auth/actions';
import { getNotificationsArray } from '@redux/reducers/notifications';
import { Drawer, makeStyles, IconButton, Link, Badge } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Scrollbars from 'react-custom-scrollbars';
import Category from '@pages/category';
import Settings from '@pages/settings';

import Logo from '@assets/logo.svg?tag';
import styles from './styles.m.scss';
import HelpIcon from '@material-ui/icons/Help';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import FlareIcon from '@material-ui/icons/Flare';
import LogRocket from 'logrocket';
import Plausible from 'plausible-tracker';
import NewUserWizard from '@components/new-user-wizard';
import {setActiveWizardStep, setNewWizardStep, setWizardIsActive} from '@redux/reducers/wizard/actions';
import {isAdmin, isAdminOrModerator} from '@services/user-role';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer - 300
  },
  drawer: {
    flexShrink: 0,
    width: 260,
    zIndex: theme.zIndex.drawer - 200
  }
}));

interface IReduxProps extends PropsWithChildren<any> {
  isAccountDeactivated: boolean;
  isTokenOutdated: boolean;
  countNotifications: number;
  modalWindows: any;
  saveUserRoute: typeof saveUserRoute;
  setActiveWizardStep: typeof setActiveWizardStep;
  setWizardIsActive: typeof setWizardIsActive;
  setNewWizardStep: typeof setNewWizardStep;
  profile: IProfile;
  wizardState: IWizardState;
}

function Wrapper(props: IReduxProps) {
  const classes = useStyles({});
  const [drawerState, setDrawer] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const [
    notificationEl,
    setNotificationEl
  ] = React.useState<HTMLElement | null>(null);
  const [openWizard, setOpenWizard] = React.useState<boolean>(false);
  const history = useHistory();
  const plausible = Plausible({
    domain: `${process.env.DOMAIN}`
  });

  const _wizardRef = createRef<HTMLDivElement>();

  const toggleDrawer = () => {
    setDrawer(!drawerState);
  };

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    LogRocket.identify(props.profile.id, {
      email: props.profile.data.email
    });
  }, []);

  useEffect(() => {
    props.setNewWizardStep({
      content: {
        text: 'You are now ready to store your assets with Neatly! If you want to revisit this wizard, you can click on "Open Wizard" button anytime.',
        title: 'Congrats!',
      },
      position: _wizardRef?.current?.getBoundingClientRect(),
      step: 5,
    });
  }, [props.wizardState.activeStep, props.wizardState.isWizardActive]);

  useEffect(() => {
    if (props.isTokenOutdated) {
      props.saveUserRoute(history.location.pathname);
      history.push('/auth/session-expired');
    } else if (props.isAccountDeactivated) {
      props.saveUserRoute(history.location.pathname);
      history.push('/auth/account-deactivated');
    }
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  });

  const handleNotifications = (value: HTMLElement) => {
    setNotificationEl(value);
  };

  const openWizardWithAnalytics = () => {
    plausible.trackEvent('Open Wizard', {
      props: {
        step: 'open modal'
      }
    });
    props.setWizardIsActive(true);
    props.setActiveWizardStep(0);
    props.setNewWizardStep({
      content: {
        text: 'You are now ready to store your assets with Neatly! If you want to revisit this wizard, you can click on the "Open Wizard" button anytime.',
        title: 'Congrats!',
      },
      position: _wizardRef?.current?.getBoundingClientRect(),
      step: 5,
    });
    localStorage.setItem('needsWizard', String(true));
  };

  return (
      <NewUserWizard>
        <div className={styles.wrapper}>
          <div className={styles.main}>
            <Drawer
                open={drawerState}
                onClose={toggleDrawer}
                className={classNames(classes.drawer, styles.drawer)}
                variant={width > 768 ? 'permanent' : 'temporary'}
                ModalProps={{ keepMounted: true }}
            >
              <RouterLink to="/" className={styles.logoRef}>
                <div className={styles.logo}>
                  <Logo />
                </div>
              </RouterLink>
              <div className={styles.btnWrap}>
                <IconButton className={styles.toggleBtn} onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Scrollbars
                  className={styles.categoriesList}
                  autoHide={true}
                  autoHideTimeout={1000}
                  autoHideDuration={200}
              >
                <DashboardTabs history={history} />
              </Scrollbars>
              <div>
                <div className={styles.tools}>
                  <Link
                      href="https://useneatly.com/help/"
                      target="_blank"
                      className={styles.helpCenter}
                  >
                    <HelpIcon fontSize="small" /> Help Center
                  </Link>
                  <div
                      className={styles.settings}
                      onClick={(event) => setNotificationEl(event.currentTarget)}
                  >
                    <Badge
                        badgeContent={props.countNotifications}
                        color="error"
                        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                      <NotificationsIcon fontSize="small" />
                    </Badge>
                    <span>Notifications</span>
                  </div>
                  <RouterLink to="/settings/profile">
                    <div className={styles.settings}>
                      <SettingsIcon fontSize="small" />
                      <span>Settings</span>
                    </div>
                  </RouterLink>
                  {isAdminOrModerator(props.profile.role) && (
                      <div
                          className={styles.settings}
                          onClick={openWizardWithAnalytics}
                          ref={_wizardRef}
                      >
                        <FlareIcon fontSize="small" />
                        <span>Open Wizard</span>
                      </div>
                  )}
                </div>
                <SyncGoogleDrive />
              </div>
            </Drawer>
            <div className={styles.content}>
              {[
                '/settings/profile',
                '/settings/billing-info',
                '/settings/user-list',
                '/settings/tags',
              ].some((path: string) => history.location.pathname === path) ? (
                  <Settings />
              ) : (
                  <Category />
              )}
            </div>
          </div>
          <Notifications
              popperEl={notificationEl}
              setPopperEl={handleNotifications}
          />
        </div>
      </NewUserWizard>
  );
}

const mapStateToProps = (state: IStore) => {
  const notifications = getNotificationsArray(state.notificationsState);
  return {
    countNotifications: notifications?.length,
    isAccountDeactivated: state.auth.isAccountDeactivated,
    isTokenOutdated: state.auth.isTokenOutdated,
    modalWindows: state.modalWindowState.modalWindows,
    profile: state.auth.profile,
    wizardState: state.wizardState,
  };
};

const actions = {
  saveUserRoute,
  setActiveWizardStep,
  setNewWizardStep,
  setWizardIsActive,
};

export default connect(mapStateToProps, actions)(Wrapper);
