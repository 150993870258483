import Types from './types';

export const fetchCategories = () => ({
  type: Types.FETCH_CATEGORIES_REQUEST
});

export const createCategory = (name: string, actionId?: string) => ({
  payload: { name, actionId },
  type: Types.CREATE_CATEGORY_REQUEST
});

export const getCategorySharableLink = (categoryId: string) => {
  return {
    payload: { categoryId },
    type: Types.GET_CATEGORY_SHARABLE_LINK
  };
};

export const updateProject = (id: string, name: string) => ({
  payload: { id, name },
  type: Types.UPDATE_PROJECT_REQUEST
});

export const updateCategory = (id: string, name: string) => ({
  payload: { id, name },
  type: Types.UPDATE_CATEGORY_REQUEST
});

export const deleteCategory = (id: string) => ({
  payload: id,
  type: Types.DELETE_CATEGORY_REQUEST
});

export const toggleNotitficationsCategory = (id: string) => ({
  payload: id,
  type: Types.NOTIFICATIONS_CATEGORY_REQUEST
});

export const setActiveCategory = (activeCategory: ICategory) => ({
  payload: activeCategory,
  type: Types.SET_ACTIVE_CATEGORY
});

export const setActiveFileTagFilter = (tags: IFileTag[]) => ({
  payload: { tags },
  type: Types.SET_ACTIVE_FILE_TAG_FILTER
});

export const syncCategoryWithDriveDirectory = (categoryId: string, directoryId: string, sectionId?: string) => ({
  payload: { categoryId, directoryId, sectionId },
  type: Types.SYNC_CATEGORY_REQUEST
});

export const changeCategoryPermission = (event: INotification, changedCategory: ICategory) => ({
  payload: { event, changedCategory },
  type: Types.UPDATE_CATEGORY_PERMISSION
});

export const removeCategoryPermission = (event: INotification, changedCategory: ICategory) => ({
  payload: { event, changedCategory },
  type: Types.REMOVE_CATEGORY_PERMISSION
});
