import React, {createRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { isAdminOrModerator, isUser } from '@services/user-role';
import {
  Input,
  IconButton,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  withStyles,
  Button,
  MenuList,
  TablePagination,
  Table,
  TableBody,
  Checkbox, Tooltip
} from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import TooltipSet from '@components/tooltips';
import ModalWindow from '@components/modal-window';
import AddFile from '@components/modal-add-file';
import AddGroupFiles from '@components/modal-add-group-files';
import Loader from '@components/loader';
import { FilesModalWindows } from '@components/modal-window/modalWindowsTypes';
import classNames from 'classnames';
import Share from '@components/modal-share';
import { useStylesCheckbox } from '@styles/checkbox';

import Group from '@assets/svg/groupVersions.svg?tag';
import TrashBin from '@assets/svg/trashBin.svg?tag';
import File from '@assets/svg/file.svg?tag';
import Pen from '@assets/svg/pen.svg?tag';
import styles from './styles.m.scss';
import ImageTooltip from '@components/tooltips/image-tooltip';
import LinkIcon from '@material-ui/icons/Link';
import FileTags from '@pages/category/components/file/file-tags';
import { setNewWizardStep } from '@redux/reducers/wizard/actions';

interface IProps {
  accessToken: string;
  activeCategory: ICategory;
  activeFileFromState: IFile;
  activeFile: IFile | null;
  addFileDropdownEl: null | HTMLButtonElement;
  files: IFile[];
  filesInGroup: IFile[];
  page: number;
  rowsPerPage: number;
  typeInput: FileInputType;
  titleInvalid: boolean;
  defaultTitle: string;
  defaultDescription: string;
  name: string;
  moreOptionEl: null | HTMLElement;
  remoteCount: number;
  isOpenAddFile: boolean;
  typeFile: GoogleDocumentType;
  isLoading: boolean;
  isLoadingOnFetch: boolean;
  isOpenAddGroupFiles: boolean;
  sectionId: string;
  isContentGroupFilesModal: boolean;
  activeGroup: IFile;
  shareFileId: string;
  selectedFilesIds: string[];
  canEditSelected: boolean;
  isConfirmModalOpen: boolean;
  isConfirmSelectedModalOpen: boolean;
  isShareModalOpen: boolean;

  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleRename: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  handleInputBlur: () => void;
  discardChange: () => void;
  openSectionItem: (item: IFile) => void;
  toggleInput: (file: IFile, type: FileInputType) => void;
  handleOpenOptions: (
    event: React.MouseEvent<HTMLButtonElement>,
    file: IFile
  ) => void;
  handleCloseMoreOption: () => void;
  handleDeleteFile: (isForApp?: boolean) => void;
  handleOpenModalAddFile: (value: boolean | IPickerResponse) => void;
  handleCloseAddFile: () => void;
  handleChangePage: (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onClickAddMoreFiles: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleSaveValueFromModal: (name: string, url: string) => void;
  handleChangeToGroup: () => void;
  handleFileSave: (file: File) => void;
  handleError: () => void;
  createFileDoc: (name: string, type: GoogleDocumentType) => void;
  isModalWindowOpen: (type: string) => boolean;
  closeFilesSuccessModal: (isAll: boolean) => void;
  deleteFiles: (value: boolean, selected?: boolean) => void;
  handleModalAddGroupFiles: (value: boolean) => void;
  handleSelectAllFiles: (isSelected: boolean) => void;
  handleSelectFile: (file: IFile, isSelected: boolean) => void;
  handleShareClose: (value: boolean, fileId?: string) => void;
  setNewWizardStepFunc: typeof setNewWizardStep;
  wizardState: IWizardState;

  _nameInputRef: React.RefObject<HTMLInputElement>;
  _openRef: React.RefObject<HTMLAnchorElement>;
}

const MenuFileItem = withStyles(() => ({
  root: {
    color: '#757575',
    padding: '5px 25px'
  }
}))(MenuItem);

const ERROR_MESSAGE_FILE_TITLE_REQUIRED =
  'Name of the files has to have at least 2 letters';
const ERROR_MESSAGE_GROUP_TITLE_REQUIRED =
  'Name of the groups has to have at least 2 letters';

const Content = (props: IProps) => {
  const {
    activeFile,
    activeCategory,
    activeFileFromState,
    accessToken,
    addFileDropdownEl,
    files,
    filesInGroup,
    page,
    rowsPerPage,
    typeInput,
    titleInvalid,
    name,
    moreOptionEl,
    remoteCount,
    isOpenAddFile,
    typeFile,
    isLoading,
    isLoadingOnFetch,
    isOpenAddGroupFiles,
    defaultTitle,
    sectionId,
    isContentGroupFilesModal,
    activeGroup,
    shareFileId,
    selectedFilesIds,
    canEditSelected,
    isConfirmModalOpen,
    isConfirmSelectedModalOpen,
    isShareModalOpen,

    handleInputChange,
    handleRename,
    handleInputBlur,
    discardChange,
    openSectionItem,
    toggleInput,
    handleOpenOptions,
    handleCloseMoreOption,
    handleDeleteFile,
    handleOpenModalAddFile,
    handleCloseAddFile,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSaveValueFromModal,
    handleChangeToGroup,
    handleFileSave,
    handleError,
    createFileDoc,
    isModalWindowOpen,
    closeFilesSuccessModal,
    deleteFiles,
    handleModalAddGroupFiles,
    onClickAddMoreFiles,
    handleSelectAllFiles,
    handleSelectFile,
    handleShareClose,
    setNewWizardStepFunc,
    _nameInputRef,
    _openRef
  } = props;

  const _wizardRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    if (props.wizardState.isWizardActive) {
      setNewWizardStepFunc({
        content: {
          text: 'Let\'s add some files to our new section.',
          title: 'Adding files',
        },
        position: _wizardRef?.current?.getBoundingClientRect(),
        step: 2,
      });
    }
  }, [props.wizardState.activeStep]);

  const classes = useStylesCheckbox({});

  const confirmMessage = {
    action: ', remove it!',
    text: (
      <p>
        This will not delete the file from <span>Google Drive</span>
      </p>
    ),
    title: (
      <h1>
        Remove <span>{activeFileFromState && activeFileFromState.name}</span>{' '}
        from Neatly?
      </h1>
    )
  };

  const successMessage = {
    action: 'Successfully Removed!',
    text: (
      <p>
        Your <span>{activeFileFromState && activeFileFromState.name}</span>{' '}
        section has been removed
      </p>
    ),
    title: <h1>Successfully Removed!</h1>
  };

  const confirmSelectedMessage = {
    action: ', remove it!',
    text: (
      <p>
        This will not delete the file from <span>Google Drive</span>
      </p>
    ),
    title: (
      <h1>
        Remove <span>all selected files</span> from Neatly?
      </h1>
    )
  };

  const successSelectedMessage = {
    action: 'Successfully Removed!',
    text: (
      <p>
        <span>All selected files</span> section has been removed
      </p>
    ),
    title: <h1>Successfully Removed!</h1>
  };

  const groupLabel = (size: number) => (
    <>
      <div className={styles.countItemsLabel}>{size}</div>
      <h3 className={styles.labelGroup}>Group:</h3>
    </>
  );

  const itemContent = (item: IFile) => (
      <h3
          onClick={() => openSectionItem(item)}
          className={classNames(
              styles.fileNameLabel,
              { [styles.groupNameLabel]: item.isGroup }
          )}
      >
        {item.name}
      </h3>
  );

  const errorMessage =
    activeFile && activeFile.isGroup
      ? ERROR_MESSAGE_GROUP_TITLE_REQUIRED
      : ERROR_MESSAGE_FILE_TITLE_REQUIRED;

  const editBtnByRole = (item: IFile) =>
    (item.canEdit || isAdminOrModerator(activeCategory?.userRole)) && (
      <IconButton
        onClick={() => toggleInput(item, 'name')}
        className={classNames(styles.editBtn, styles.onHover)}
      >
        <Pen className={styles.pen} />
      </IconButton>
    );

  const itemRow = (item: IFile, key: number) => {
    const nameItem =
      activeFile && item.id === activeFile.id && typeInput === 'name' ? (
        <>
          <TooltipSet message={errorMessage} isOpen={titleInvalid} type="error">
            <Input
              className={styles.fileNameInput}
              error={titleInvalid}
              value={name}
              autoFocus={true}
              onChange={handleInputChange}
              onKeyPress={handleRename}
              inputRef={_nameInputRef}
            />
          </TooltipSet>
          <IconButton
            className={classNames(styles.done)}
            onClick={handleInputBlur}
          >
            <DoneRoundedIcon />
          </IconButton>
          <IconButton
            className={classNames(styles.clear)}
            onClick={discardChange}
          >
            <ClearRoundedIcon />
          </IconButton>
        </>
      ) : (
        <>
          {item.isGroup && groupLabel(item.filesCount)}
          {item.data?.fileType === 'link' ? (
              <div className={styles.linkItem}>
                <Tooltip title={`This asset is an external link.`}>
                  <LinkIcon className={styles.linkIcon} />
                </Tooltip>
                <div className={styles.linkItemContent}>
                  {itemContent(item)}
                </div>
              </div>
          ) : (
              <ImageTooltip file={item}>
                {itemContent(item)}
              </ImageTooltip>
          )}
          {editBtnByRole(item)}
        </>
      );

    const date = new Date(item.created.at);
    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    };
    const fileDate = date.toLocaleDateString('en-US', options);

    const shareMenu = !isContentGroupFilesModal &&
      isAdminOrModerator(activeCategory?.userRole) && (
        <MenuFileItem onClick={() => handleShareClose(true, activeFile.id)}>
          Share
        </MenuFileItem>
      );

    const changeToFileGroupMenu = !isContentGroupFilesModal &&
      activeFile &&
      !activeFile.isGroup && (
        <MenuFileItem onClick={handleChangeToGroup}>
          Change to file group
        </MenuFileItem>
      );

    const changeToFileGroupMenuByRole = activeCategory && changeToFileGroupMenu;

    const removeItemByRole = activeCategory && (
      <MenuFileItem onClick={() => handleDeleteFile(false)}>
        Remove
      </MenuFileItem>
    );

    const btnMoreOption = (
      <IconButton
        onClick={(event) => handleOpenOptions(event, item)}
        disabled={
          !(item.canEdit || isAdminOrModerator(activeCategory?.userRole))
        }
      >
        <MoreHorizIcon />
      </IconButton>
    );

    const checkBox = isAdminOrModerator(activeCategory?.userRole) && (
      <TableCell className={styles.fileCheckbox}>
        <Checkbox
          checked={selectedFilesIds.includes(item.id)}
          checkedIcon={
            <span className={classNames(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          onChange={(e) => handleSelectFile(item, e.target.checked)}
          className={classNames(classes.root, styles.selectFile)}
        />
      </TableCell>
    );

    return (
      <TableRow key={key} className={styles.tableRow}>
        {checkBox}
        <TableCell className={styles.fileName}>
          <div className={styles.titleWrap}>{nameItem}</div>
          <div className={styles.descriptionWrap}>{<FileTags file={item}/>}</div>
        </TableCell>
        <TableCell className={styles.createdByAt}>
          <pre>Created: {fileDate} by </pre>
          <p>{item.created.by}</p>
        </TableCell>
        <TableCell className={styles.moreOption}>
          {btnMoreOption}
          <Menu
            elevation={1}
            anchorEl={moreOptionEl}
            open={Boolean(moreOptionEl)}
            getContentAnchorEl={null}
            onClose={handleCloseMoreOption}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {shareMenu}
            {changeToFileGroupMenuByRole}
            {removeItemByRole}
          </Menu>
        </TableCell>
      </TableRow>
    );
  };

  const fileRows =
    sectionId && files
      ? files.slice(0, rowsPerPage === -1 ? files.length : rowsPerPage).map((item, key) => itemRow(item, key))
      : null;

  const fileInGroupRows =
    activeGroup && filesInGroup
      ? filesInGroup.map((item, key) => itemRow(item, key))
      : null;

  const fileRowsContent = !isContentGroupFilesModal
    ? fileRows
    : fileInGroupRows;

  const addFileBtnClasses = classNames(
    styles.addFileBlock,
    isContentGroupFilesModal && styles.addFileBlockInGroupModal,
    files && files && files.length > 0 ? styles.spaceTop : ''
  );

  const addFileTitleLabel = !isContentGroupFilesModal && (
    <p className={styles.addFileTitle}>{defaultTitle}</p>
  );

  const groupVersionsOfFileBtn = !isContentGroupFilesModal && (
    <Button
      variant="contained"
      className={classNames(styles.addFileBtn, styles.groupBtn)}
      startIcon={<Group />}
      onClick={() => handleModalAddGroupFiles(true)}
    >
      Group versions of a file
    </Button>
  );

  const addFirstFile = (
    <div className={addFileBtnClasses}>
      {addFileTitleLabel}
      <div className={styles.addFileButtons}>
        <Button
          variant="contained"
          className={classNames(styles.addFileBtn, styles.fileBtn)}
          startIcon={<File />}
          onClick={() => handleOpenModalAddFile(true)}
          ref={_wizardRef}
        >
          Add files
        </Button>
        {groupVersionsOfFileBtn}
      </div>
    </div>
  );

  const addFilesDropDown = (
    <>
      <Button
        className={classNames(
          styles.addMoreFilesBtn,
          isContentGroupFilesModal && styles.addMoreFilesBtnContent
        )}
        onClick={onClickAddMoreFiles}
        disabled={filesInGroup && filesInGroup.length > 19}
      >
        <span className={styles.plusInBtn}>+</span> Add More Files
      </Button>
      <Menu
        open={Boolean(addFileDropdownEl)}
        onClose={handleCloseAddFile}
        anchorEl={addFileDropdownEl}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuList id="menu-list-grow">
          <MenuFileItem onClick={() => handleOpenModalAddFile(true)}>
            Add file(s)
          </MenuFileItem>
          <MenuFileItem onClick={() => handleModalAddGroupFiles(true)}>
            Add a file group
          </MenuFileItem>
        </MenuList>
      </Menu>
    </>
  );

  const contentUnderTable =
    (!isContentGroupFilesModal && remoteCount > 0) ||
    (isContentGroupFilesModal && activeGroup)
      ? addFilesDropDown
      : addFirstFile;

  const pagination =
      remoteCount > 4 && !isContentGroupFilesModal ? (
      <div>
        <br />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          component="nav"
          count={remoteCount}
          rowsPerPage={rowsPerPage}
          page={page}
          className={styles.pagination}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    ) : null;

  const modalAddFile = isOpenAddFile && (
    <AddFile
      sectionId={sectionId}
      activeCategory={activeCategory}
      open={isOpenAddFile}
      setOpen={handleOpenModalAddFile}
      save={handleSaveValueFromModal}
      saveFile={handleFileSave}
      typeFile={typeFile}
      accessToken={accessToken}
      handleError={handleError}
      createGoogleDocFile={createFileDoc}
    />
  );

  const modalAddGroupFiles = isOpenAddGroupFiles && (
    <AddGroupFiles
      open={isOpenAddGroupFiles}
      sectionId={sectionId}
      setOpen={handleModalAddGroupFiles}
    />
  );

  const shareModal = isShareModalOpen && (
    <Share
      open={isShareModalOpen}
      setOpen={handleShareClose}
      userShareRole={''}
      filesIds={selectedFilesIds}
      activeFileId={shareFileId}
      isGroup={activeFile?.isGroup}
      link={activeFile?.data?.fileType === 'link' ? activeFile?.data?.fileUrl : `https://drive.google.com/file/d/${activeFile?.data?.fileId}/view`}
      isLink={activeFile?.data?.fileType === 'link'}
    />
  );

  const contentUnderTableInScrollbar =
    ((!isContentGroupFilesModal && rowsPerPage * (page + 1) < 5) ||
      (isContentGroupFilesModal &&
        (!filesInGroup || filesInGroup.length < 4))) &&
    contentUnderTable;

  const contentUnderTableAfterScrollbar =
    ((!isContentGroupFilesModal && rowsPerPage * (page + 1) > 4) ||
      (isContentGroupFilesModal && filesInGroup && filesInGroup.length > 3)) &&
    contentUnderTable;

  const contentUnderTableInScrollbarByRole =
    activeCategory &&
    isAdminOrModerator(activeCategory.userRole) &&
    contentUnderTableInScrollbar;

  const contentUnderTableAfterScrollbarByRole =
    activeCategory &&
    isAdminOrModerator(activeCategory.userRole) &&
    contentUnderTableAfterScrollbar;

  const noFilesLabel = !fileRowsContent &&
    activeCategory &&
    isUser(activeCategory.userRole) && (
      <p className={styles.noFilesLabel}>No files yet.</p>
    );

  const deleteSelectedButton = (canEditSelected ||
    isAdminOrModerator(activeCategory?.userRole)) && (
    <div
      className={styles.deleteSelectedBtn}
      onClick={() => handleDeleteFile(true)}
    >
      <TrashBin />
      <p>Delete Files</p>
    </div>
  );

  const shareBtn = (!isContentGroupFilesModal || canEditSelected) && (
    <Button
      color="primary"
      variant="outlined"
      className={styles.shareSelected}
      onClick={() => handleShareClose(true)}
    >
      Share
    </Button>
  );

  const actions = selectedFilesIds.length > 0 && (
    <>
      {shareBtn}
      {deleteSelectedButton}
    </>
  );

  const checkAllFilesSelected = () => {
    if (isContentGroupFilesModal) {
      return (
        selectedFilesIds &&
        filesInGroup &&
        selectedFilesIds.length === filesInGroup.length
      );
    } else {
      return selectedFilesIds && selectedFilesIds.length === rowsPerPage * (page + 1);
    }
  };

  const groupedActions = ((isAdminOrModerator(activeCategory?.userRole) &&
    !isContentGroupFilesModal &&
    remoteCount > 0) ||
    (isAdminOrModerator(activeCategory?.userRole) &&
      isContentGroupFilesModal &&
      filesInGroup &&
      filesInGroup.length)) && (
    <div className={styles.groupedActions}>
      <div className={styles.selectAllWrap}>
        <Checkbox
          checked={checkAllFilesSelected()}
          checkedIcon={
            <span className={classNames(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          onChange={(e) => handleSelectAllFiles(e.target.checked)}
          className={classNames(classes.root, styles.selectAll)}
        />
        <p>Select All</p>
      </div>
      {actions}
    </div>
  );

  const content =
    isLoadingOnFetch || isLoading ? (
      <div className={styles.loader}>
        <Loader isLoading={true} />
      </div>
    ) : (
      <div
        className={classNames(
          styles.wrapper,
          isContentGroupFilesModal && styles.modalWrapper,
          ((!isContentGroupFilesModal && rowsPerPage * (page +  1) >= 5) ||
            (isContentGroupFilesModal &&
              (!filesInGroup || filesInGroup.length >= 4))) &&
            styles.small
        )}
      >
        {groupedActions}
        <div className={styles.tableContainer} style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <Table className={styles.table}>
            <TableBody>
              {fileRowsContent}
            </TableBody>
          </Table>
          {contentUnderTableInScrollbarByRole}
          {noFilesLabel}
        </div>
        {contentUnderTableAfterScrollbarByRole}
      </div>
    );

  return (
    <>
      {content}
      {shareModal}
      {modalAddFile}
      {modalAddGroupFiles}
      <ModalWindow
        type="confirm"
        message={confirmMessage}
        open={isConfirmModalOpen}
        onClose={deleteFiles}
      />
      <ModalWindow
        type="success"
        message={successMessage}
        open={isModalWindowOpen(FilesModalWindows.successModal)}
        onClose={() => closeFilesSuccessModal(false)}
      />
      <ModalWindow
        type="confirm"
        message={confirmSelectedMessage}
        open={isConfirmSelectedModalOpen}
        onClose={(value: boolean) => deleteFiles(value, true)}
      />
      <ModalWindow
        type="success"
        message={successSelectedMessage}
        open={isModalWindowOpen(FilesModalWindows.successSelectedModal)}
        onClose={() => closeFilesSuccessModal(true)}
      />
      {pagination}
      <Link
        style={{ display: 'none' }}
        innerRef={_openRef}
        to=""
        target="_blank"
      />
    </>
  );
};

export default Content;
