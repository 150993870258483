import * as React from 'react';
import {Chip, styled, TextField, Tooltip} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Component} from 'react';
import {connect} from 'react-redux';
import {fetchTags, updateFile} from '@redux/reducers/files/actions';
import styles from './styles.m.scss';
import {FileTagModal} from '@components/modal-window/modalWindowsTypes';
import AddIcon from '@material-ui/icons/Add';
import {openModalWindow} from '@redux/reducers/modal-window/actions';

interface IOwnProps {
    file: IFile;
}

interface IReduxProps {
    allCompanyFileTags: IFileTag[];
    updateFile: typeof updateFile;
    fetchTags: typeof fetchTags;
    tagsLoading: boolean;
    companyId: string;
    isOwner: boolean;
    openModalWindow: typeof openModalWindow;
}

interface IProps extends IReduxProps, IOwnProps {}

interface IState {
    addTags: boolean;
    tags: IFileTag[];
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

class FileTags extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            addTags: false,
            tags: props.file.tags
        };
    }

    handleAutocompleteOnChange = (event: React.ChangeEvent<{}>, value: IFileTag[]) => {
        const tagNameSet = new Set(value.map(({ name }) => name));
        const uniqueTags: IFileTag[] = [];
        value.forEach((tag: IFileTag) => {
            if (!tagNameSet.has(tag.name)) { return; }
            uniqueTags.push(tag);
            tagNameSet.delete(tag.name);
        });
        this.setState({
            addTags: true,
            tags: uniqueTags,
        });
    };

    handleAutocompleteOnBlur = () => {
        this.props.updateFile(this.props.file, this.props.file.name, this.state.tags);
        this.setState({ addTags: false });
    };

    handleDelete = (fileTag: IFileTag) => () => {
        const { updateFile: updateFileProp, file } = this.props;
        const { tags } = this.state;

        const updatedTags = tags.filter((tag) => tag.name !== fileTag.name);

        updateFileProp(file, file.name, updatedTags);

        this.setState((prevState) => ({
            addTags: false,
            tags: prevState.tags.filter((tag) => tag.name !== fileTag.name)
        }));
    };

    handleAddTagsClick = () => {
        const { fetchTags: fetchTagsProp, companyId } = this.props;
        fetchTagsProp();
        this.setState({addTags: true});
    };

    handleAutocompleteKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            this.props.updateFile(this.props.file, this.props.file.name, this.state.tags);
            this.setState({ addTags: false });
        }
    };

    getAutocompleteOptions = () => {
        const defaultTag = {
            default: true,
            name: 'Define new tag...'
        };
        if (this.props.isOwner) {
            return [...(this.props.allCompanyFileTags ?? []), defaultTag];
        }

        return this.props.allCompanyFileTags;
    };

    render() {
        const { addTags } = this.state;

        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    listStyle: 'none'
                }}
            >
                {addTags ? (
                    <Autocomplete
                        onChange={this.handleAutocompleteOnChange}
                        multiple={true}
                        id="tags-standard"
                        value={this.state.tags}
                        options={this.getAutocompleteOptions()}
                        loading={this.props.tagsLoading}
                        style={{ paddingBottom: '40px' }}
                        renderOption={(option) => (
                            option.default ?
                                (
                                    <React.Fragment>
                                        <Tooltip title={this.props.allCompanyFileTags?.length >= 20 ? 'You have a maximum of 20 tags defined already.' : ''}>
                                            <button
                                                style={{width: '100%'}}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.props.openModalWindow(FileTagModal.addNewModal, 'fileTag');
                                                }}
                                                disabled={this.props.allCompanyFileTags?.length >= 20}
                                            >
                                                <div style={{ color: 'silver' }}>
                                                    <AddIcon />
                                                </div>
                                                <div style={{ color: 'silver' }}>
                                                    {option.name}
                                                </div>
                                            </button>
                                        </Tooltip>
                                    </React.Fragment>
                                ) :
                                (
                                    <React.Fragment>
                                        {option.name}
                                    </React.Fragment>
                                )
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option: IFileTag, index: number) => (
                                <Chip
                                    key={index}
                                    label={option.name}
                                    {...getTagProps({ index })}
                                    style={{ backgroundColor: option.color }} // Set color here
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Add tags"
                                autoFocus={true}
                                onBlur={this.handleAutocompleteOnBlur}
                                onKeyDown={this.handleAutocompleteKeyDown}
                                style={{ width: 'auto', height: '40px' }}
                            />
                        )}
                    />
                ) : (
                    <>
                        {this.props.file.tags?.map((data, index) => (
                            <ListItem key={this.props.file.tags.length + index} style={{ marginRight: '8px' }}>
                                <Tooltip title={data.description}>
                                    <Chip
                                        label={data.name}
                                        style={{ backgroundColor: data.color, cursor: 'pointer' }}
                                        onDelete={this.handleDelete(data)}
                                        size={'small'}
                                    />
                                </Tooltip>
                            </ListItem>
                        ))}
                        {this.state.tags?.length < 5 && (
                            <ListItem key="addNewTag" style={{ marginBottom: '8px' }}>
                                <button
                                    onClick={this.handleAddTagsClick}
                                    className={styles.addTagBtn}
                                >
                                    Add new tag
                                </button>
                            </ListItem>
                        )}
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IStore) => ({
    allCompanyFileTags: state.filesState.tags,
    companyId: state.auth.profile.company.companyId,
    isOwner: state.auth.profile.company.owner,
    tagsLoading: state.filesState.fileTagsLoader,
});

const actions = {
    fetchTags,
    openModalWindow,
    updateFile,
};

export default connect(mapStateToProps, actions)(FileTags);
