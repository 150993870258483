import Types from './types';

const initialState: ICategoriesState = {
  activeCategory: null,
  categories: new Array<ICategory>(),
  errors: [],
  fileTagFilter: [],
  isLoadingCategory: false,
  isLoadingOnFetch: false,
  pendingNotificCategoryId: '',
  shareLink: null
};

export default (state: ICategoriesState = initialState, action: IAction) => {
  switch (action.type) {
    case Types.SET_ACTIVE_CATEGORY: {
      const newState = {
        ...state,
        fileTagFilter: [] as IFileTag[],
      };
      if (action.payload) {
        newState.activeCategory = action.payload;
      } else {
        newState.activeCategory = null;
      }

      return newState;
    }

    case Types.GET_CATEGORY_SHARABLE_LINK_SUCCESS: {
      return {
        ...state,
        shareLink: action.payload.shareLink
      };
    }

    case Types.GET_CATEGORY_SHARABLE_LINK_ERROR: {
      return {
        ...state,
        shareLink: null
      };
    }

    case Types.SET_ACTIVE_FILE_TAG_FILTER: {
      const newState = {
        ...state,
      };
      if (action.payload) {
        newState.fileTagFilter = action.payload.tags;
      } else {
        newState.fileTagFilter = [];
      }

      return newState;
    }

    case Types.FETCH_CATEGORIES_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoadingCategory: true,
        isLoadingOnFetch: true
      };
    }

    case Types.NOTIFICATIONS_CATEGORY_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoadingCategory: true,
        isLoadingOnFetch: false,
        pendingNotificCategoryId: action.payload
      } as ICategoriesState;
    }

    case Types.CREATE_CATEGORY_REQUEST:
    case Types.DELETE_CATEGORY_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoadingCategory: true,
        isLoadingOnFetch: false
      };
    }

    case Types.UPDATE_CATEGORY_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoadingCategory: true,
        isLoadingOnFetch: false
      };

      newState.categories.find((cat) => cat.id === action.payload.id).name =
        action.payload.name;

      return newState;
    }

    case Types.NOTIFICATIONS_CATEGORY_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false,
        pendingNotificCategoryId: ''
      };
    }

    case Types.FETCH_CATEGORIES_ERROR:
    case Types.CREATE_CATEGORY_ERROR:
    case Types.DELETE_CATEGORY_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoadingCategory: false,
        isLoadingOnFetch: false
      };
    }

    case Types.UPDATE_CATEGORY_ERROR: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoadingCategory: false,
        isLoadingOnFetch: false
      };

      newState.categories.find(
        (cat) => cat.id === newState.activeCategory.id
      ).name = newState.activeCategory.name;

      return newState;
    }

    case Types.FETCH_CATEGORIES_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoadingCategory: false,
        isLoadingOnFetch: false,
      };

      const categories = action.payload as ICategory[];
      newState.categories = categories;

      return newState;
    }

    case Types.CREATE_CATEGORY_SUCCESS: {
      const newState = {
        ...state,
        categories: [...state.categories],
        errors: new Array<string>(),
        isLoadingCategory: false,
        isLoadingOnFetch: false
      };
      const category = action.payload as ICategory;
      if (category.id) {
        newState.categories.push(action.payload);
      }

      newState.activeCategory = category;

      return newState;
    }

    case Types.DELETE_CATEGORY_SUCCESS: {
      const newState = {
        ...state,
        categories: [...state.categories],
        errors: new Array<string>(),
        isLoadingCategory: false,
        isLoadingOnFetch: false
      };

      newState.categories = state.categories.filter(
        (cat) => cat.id !== newState.activeCategory.id
      );
      return newState;
    }

    case Types.NOTIFICATIONS_CATEGORY_SUCCESS: {
      const newState = {
        ...state,
        categories: [...state.categories],
        errors: new Array<string>(),
        isLoadingCategory: false,
        isLoadingOnFetch: false
      };

      newState.categories.find(
        (cat) => cat.id === newState.pendingNotificCategoryId
      ).subscribed = !newState.categories.find(
        (cat) => cat.id === newState.pendingNotificCategoryId
      )?.subscribed;

      return newState;
    }

    case Types.UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        errors: [],
        isLoadingCategory: false,
        isLoadingOnFetch: false
      };
    }

    case Types.UPDATE_CATEGORY_PERMISSION: {
      const newState = { ...state };

      const changedCategory = action.payload.changedCategory as ICategory;

      newState.categories.find(
          (category) => category.id === changedCategory.id
      ).userRole = action.payload.event.message;

      if (newState.activeCategory?.id === changedCategory.id) {
        newState.activeCategory.userRole = action.payload.event.message;
      }

      return newState;
    }

    case Types.REMOVE_CATEGORY_PERMISSION: {
      const newState = { ...state };

      const changedCategory = action.payload.changedCategory as ICategory;

      if (newState.activeCategory?.id === changedCategory.id) {
        newState.activeCategory = null;
      }

      newState.categories = newState.categories.filter((category) => category.id !== changedCategory.id);

      return newState;
    }

    case Types.SYNC_CATEGORY_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoadingCategory: false,
        isLoadingOnFetch: true
      };
    }

    case Types.SYNC_CATEGORY_ERROR: {
      return {
        ...state,
        isLoadingCategory: false,
        isLoadingOnFetch: false
      };
    }

    case Types.SYNC_CATEGORY_SUCCESS: {
      return {
        ...state,
        errors: [],
        isLoadingCategory: false,
        isLoadingOnFetch: false
      };
    }

    default:
      return state;
  }
};
