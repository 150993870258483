import Types from './types';

export const setToken = (token: string) => {
  return {
    payload: token,
    type: Types.SET_TOKEN
  };
};

export const accessViaSharedLink = (data: string, email: string) => {
  return {
    payload: { data, email },
    type: Types.ACCESS_VIA_LINK
  };
};

export const setAccountDeactivated = () => {
  return {
    type: Types.SET_ACCOUNT_DEACTIVATED
  };
};

export const requestTokenUpdate = (isOutdated: boolean) => {
  return {
    payload: isOutdated,
    type: Types.SET_TOKEN_OUTDATED
  };
};

export const fetchUsers = () => {
  return { type: Types.FETCH_USERS_REQUEST };
};

export const getUsersEmail = () => {
  return {
    type: Types.FETCH_USERS_EMAIL_REQUEST
  };
};

export const saveUserRoute = (route: string) => {
  return {
    payload: route,
    type: Types.USER_ROUTE
  };
};

export const logOut = () => {
  return {
    type: Types.USER_LOGOUT
  };
};

export const fetchProfile = () => ({
  type: Types.PROFILE_REQUEST
});

export const createCompany = (companyId?: string, companyName?: string, ownerEmail?: string) => ({
  payload: { companyId, companyName, ownerEmail },
  type: Types.CREATE_COMPANY_REQUEST
});

export const closeAccount = () => ({
  type: Types.CLOSE_ACCOUNT_REQUEST
});

export const inviteUsers = (
  usersData: Array<{ email: string, name?: string}>,
  categoriesIds: string[],
  role: UserRoleType,
  message?: string,
  isCoworker?: boolean,
) => ({
  payload: {
    categoriesIds,
    isCoworker,
    message,
    role,
    usersData,
  },
  type: Types.INVITE_USERS_ALLOWED
});

export const reinviteUser = (id: string) => ({
  payload: { id },
  type: Types.REINVITE_USER_REQUEST
});

export const setStatusUserList = (value: UserListStatusType) => ({
  payload: value,
  type: Types.SET_USER_LIST_STATUS
});

export const setInvitedCompanyId = (companyId: string) => ({
  payload: { companyId },
  type: Types.SET_INVITED_COMPANY_ID
});

export const setSharedLink = (encryptedData: string) => ({
  payload: { encryptedData },
  type: Types.SET_SHARED_LINK
});

export const setNewUser = (isNewUser: boolean) => ({
  payload: isNewUser,
  type: Types.SET_NEW_USER
});

export const switchUserStatus = (id: string) => ({
  payload: id,
  type: Types.SWITCH_USER_STATUS_REQUEST
});

export const switchUserRole = (userId: string, role: UserRoleType, isInvated: boolean) => {
  const url = isInvated ? '/invited' : '';
  return {
    payload: { userId, role, url },
    type: Types.SWITCH_USER_ROLE_REQUEST
  };
};

export const shareCategory = (emails: string[], categoriesIds: string[], role: UserRoleType) => ({
  payload: { emails, categoriesIds, role },
  type: Types.SHARE_CATEGORY_REQUEST
});

export const shareInvitedCategory = (userId: string, categoriesIds: string[], role: UserRoleType) => ({
  payload: { userId, categoriesIds, role },
  type: Types.SHARE_INVITED_CATEGORY_REQUEST
});

export const clearInviteError = () => ({
  type: Types.CLEAR_INVITE_ERROR
});

export const clearShareError = () => ({
  type: Types.CLEAR_SHARE_ERROR
});

export const clearCategory = (id: string, categoriesIds: string[]) => ({
  payload: { id, categoriesIds },
  type: Types.CLEAR_CATEGORY_REQUEST
});

export const clearInvitedCategory = (userId: string, categoriesIds: string[]) => ({
  payload: { userId, categoriesIds },
  type: Types.CLEAR_INVITED_CATEGORY_REQUEST
});

export const deleteUser = (id: string, isInvated: boolean) => {
  const url = isInvated ? '/invited' : '';
  return {
    payload: { id, url },
    type: Types.DELETE_USER_REQUEST
  };
};

export const setActionId = (id: string) => ({
  payload: { actionId: id },
  type: Types.SET_CREATE_ACTION_ID
});
