import Types from './types';

export const fetchSections = (categoryId: string) => ({
  payload: categoryId,
  type: Types.FETCH_SECTIONS_REQUEST
});

export const createSection = (categoryId: string, name: string) => ({
  payload: { categoryId, name },
  type: Types.CREATE_SECTION_REQUEST
});

export const updateSection = (id: string, name: string) => ({
  payload: { id, name },
  type: Types.UPDATE_SECTION_REQUEST
});

export const deleteSection = (id: string) => ({
  payload: id,
  type: Types.DELETE_SECTION_REQUEST
});

export const fetchTempSections = (categoryId: string) => ({
  payload: categoryId,
  type: Types.FETCH_TEMP_SECTIONS_REQUEST
});
