import axios from 'axios';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { isAdminOrModerator } from '@services/user-role';
import { closeModalWindow } from '@redux/reducers/modal-window/actions';
import { requestTokenUpdate } from '@redux/reducers/auth/actions';
import { syncCategoryWithDriveDirectory } from '@redux/reducers/categories/actions';
import { SyncModalWindow } from '@components/modal-window/modalWindowsTypes';
import ModalWindow from '@components/modal-window';
import SelectCategory from '@components/modal-select-category';

import Drive from '@assets/svg/google/googleDriveBlue.svg?tag';
import styles from './styles.m.scss';
import Plausible from 'plausible-tracker';

interface IReduxProps {
  accessToken: string;
  roleOfUser: UserRoleType;
  modalWindows: any;
  projects: ICategory[];
  categories: ICategory[];
  closeModalWindow: typeof closeModalWindow;
  requestTokenUpdate: typeof requestTokenUpdate;
  syncCategoryWithDriveDirectory: typeof syncCategoryWithDriveDirectory;
}

const SyncGoogleDrive = (props: IReduxProps) => {
  const { accessToken, projects, categories } = props;
  const [isOpenSelectCategoryModal, setIsOpenSelectCategoryModal] = useState(false);
  const [pickedFolderId, setPickedFolderId] = useState('');
  const plausible = Plausible({
    domain: `${process.env.DOMAIN}`
  });

  const handleError = () => {
    props.requestTokenUpdate(true);
  };

  const handlePicked = (response: IPickerResponse) => {
    if (response.action === 'picked') {
      setIsOpenSelectCategoryModal(true);
      setPickedFolderId(response.docs[0].id);
    }
  };

  const isModalWindowOpen = (type: string): boolean => {
    return !!(props.modalWindows && props.modalWindows[type]);
  };

  const onFolderSyncGoogleModal = async () => {
    let result;
    plausible.trackEvent('Sync Drive', {
      props: {
        step: 'Choose files'
      }
    });
    try {
      result = await axios.get(
        `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessToken}`
      );
      if (result.status === 200) {
        const google = (window as any).google;
        const folderView = new google.picker.DocsView()
          .setOwnedByMe(true)
          .setIncludeFolders(true)
          .setMimeTypes('application/vnd.google-apps.folder')
          .setSelectFolderEnabled(true);
        const picker = new google.picker.PickerBuilder()
          .addView(folderView)
          .enableFeature(google.picker.Feature.MINE_ONLY)
          .setOAuthToken(accessToken)
          .setDeveloperKey(process.env.DEVELOPER_KEY)
          .setAppId(process.env.GOOGLE_APP_ID)
          .setCallback(handlePicked);
        picker.build().setVisible(true);
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
    }
  };

  const onSyncCategoryOrProject = (id: string) => {
    setIsOpenSelectCategoryModal(false);

    if (!id) {
      return;
    }
    plausible.trackEvent('Sync Drive', {
      props: {
        step: 'Choose category'
      }
    });
    props.syncCategoryWithDriveDirectory(id, pickedFolderId);
  };

  const modalSelectCategoryOrProjectToSync = isOpenSelectCategoryModal && (
    <SelectCategory
      open={isOpenSelectCategoryModal}
      setOpen={(value?: boolean) => setIsOpenSelectCategoryModal(value)}
      onSync={onSyncCategoryOrProject}
    />
  );

  const emptyFolderMessage = {
    text: (
      <p>
        Can't sync an empty <span>Google Drive</span> folder. Please try again
      </p>
    ),
    title: <h1>Selected folder is empty!</h1>
  };

  const isHasAccess = () => {
    const items = projects.concat(categories);

    const hasAccess = items.some((i) => isAdminOrModerator(i.userRole));
    return hasAccess;
  };

  return (
    <div className={styles.titleWrap}>
      {(isHasAccess() || isAdminOrModerator(props.roleOfUser)) && (
        <Button
          onClick={onFolderSyncGoogleModal}
          color="primary"
          variant="outlined"
          className={styles.googleDrive}
          startIcon={<Drive />}
        >
          Drive Sync
        </Button>
      )}
      {modalSelectCategoryOrProjectToSync}
      <ModalWindow
        type="sync"
        message={emptyFolderMessage}
        open={isModalWindowOpen(SyncModalWindow.successModal)}
        onClose={() => props.closeModalWindow(SyncModalWindow.successModal)}
      />
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  accessToken: state.auth.profile.data.accessToken,
  categories: state.categoriesState.categories,
  modalWindows: state.modalWindowState.modalWindows,
  projects: state.projectState.projects,
  roleOfUser: state.auth.profile.role,
});

const actions = {
  closeModalWindow,
  requestTokenUpdate,
  syncCategoryWithDriveDirectory,
};

export default connect(mapStateToProps, actions)(SyncGoogleDrive);
